import React from "react"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Autorization } from "../../components/Upload/Autorization"
import Layout from "../../components/Layouts/uploadLayout"

export default function Upload() {
  const isMobTab = useMediaQuery("(max-width:959.5px)")
  return (
    <Layout anotherBackgroundColor={isMobTab ? "white" : "standard"}>
      <Autorization />
    </Layout>
  )
}
