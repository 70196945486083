import React from "react"

import { navigate } from "gatsby"
import Container from "@ecom/ui/components/Container"
import Form from "@ecom/ui/components/FormLanding"
import PhoneFieldWithSeven from "@ecom/ui/components/FormLanding/fields/PhoneFieldWithSeven"
import SubmitButton from "@ecom/ui/components/FormLanding/fields/SubmitButton"
import Grid from "@material-ui/core/Grid"
import { sendRequest } from "../helpers/sendRequest"
import { setIDBValue } from "../../../utils/idbUtils"
import { ISendSMS } from "./types"

import * as styles from "./autorization.module.scss"

const STATUS_AUTORIZATION = {
  NOTVERIFIED: "not verified",
  SENDED: "sended",
} as const

type TStatus = (typeof STATUS_AUTORIZATION)[keyof typeof STATUS_AUTORIZATION]

const onSubmit = (values: ISendSMS) => {
  sendRequest({ ...values, source: "credit/my_account" })
    .then(({ message, token }: { message: TStatus; token: string }) => {
      if (message === STATUS_AUTORIZATION.NOTVERIFIED) {
        return navigate("/error/")
      }

      if (message === STATUS_AUTORIZATION.SENDED) {
        setIDBValue("phone", values.phone)
        return navigate("/upload/verify-phone/", { state: { token } })
      }

      return navigate("/error/")
    })
    .catch(() => navigate("/error/"))
}

export function Autorization() {
  return (
    <section className={styles.section}>
      <Container>
        <div className={styles.containerFluid}>
          <Grid container direction="column" spacing={5}>
            <Grid item className={styles.head}>
              <h1>Добрый день!</h1>
              <p>Авторизуйтесь по номеру телефона</p>
            </Grid>
            <Grid item>
              <Form onSubmit={onSubmit}>
                <PhoneFieldWithSeven
                  label="Телефон"
                  name="phone"
                  className={styles.phone}
                  border={false}
                  clearIcon
                />
                <SubmitButton color="primary" className={styles.button}>
                  Продолжить
                </SubmitButton>
              </Form>
            </Grid>
          </Grid>
        </div>
      </Container>
    </section>
  )
}
